import React, { useState } from 'react';
import Modal from 'react-modal';
import { Container, Grid } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import ArtistBoxView from '../component/view/artistBoxView.tsx';
import './lineup.scss';
import data from '../resource/artists.json';
import ArtistModal from '../component/modal/artistModal.tsx';

interface Data {
  data: ArtistData
}

type ArtistData = {
  artistName: string,
  artistImg: string,
  day1?: boolean,
  day2?: boolean,
  day3?: boolean,
  photo?: boolean
}

const MainView = () => {

  const ArtistBoxModal = (props: Data) => {
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);

    return (
      <div className='modal-base'>
        <button className='modal-btn' onClick={() => setEditModalIsOpen(true)}>
          <ArtistBoxView data = {props.data} />
        </button>
        <Modal isOpen={editModalIsOpen} onRequestClose={() => (setEditModalIsOpen(false))} overlayClassName='overlay' className='modal-md'>
          <ArtistModal data={props.data}/>
          <div className='modal-footer'>
            <button onClick={() => (setEditModalIsOpen(false))} className='modal-close-btn'>
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className='lineup'>
      <Container maxWidth='md'>
        <TitleView english='LINE UP' japaniese='出演者情報'/>
        {/* <Grid container style={{margin: '20px 0 5px 0', alignItems: 'center', justifyContent: 'center'}}>
          <Grid item sm={4}>
            <button className='button day1'>19日</button>
          </Grid>
          <Grid item sm={4}>
            <button className='button day2'>20日</button>
          </Grid>
          <Grid item sm={4}>
            <button className='button day3'>21日</button>
          </Grid>
        </Grid> */}
        <Grid container style={{margin: '0', alignItems: 'center', justifyContent: 'center'}}>
          {Object.values(data).map((data, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} key={index}>
                <ArtistBoxView data = {data} />
              </Grid>
            );
          })}
        </Grid>
        
      </Container>
    </div>
  );
}

const LineupView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default LineupView;
