import React from 'react';
import './artistBoxView.scss';
import SlideUpAnimation from '../animation/slideUpAnimation.tsx';

interface Data {
  data: ArtistData
}

type ArtistData = {
  artistName: string,
  artistImg: string,
  day1?: boolean,
  day2?: boolean,
  day3?: boolean,
  photo?: boolean,
  concept?: string
}

const ArtistBoxView = (props: Data) => {
  const {data} = props;

  return (
    <SlideUpAnimation>
      <div className='artist-box'>
        <div className='img-area'>
          <img src={data.artistImg} alt=''/>
        </div>
        <div className='artist-name'>{data.artistName}</div>
        <div className='tag-area'>
          {data.day1 ? <span className='day1'>19日</span> : ''}
          {data.day2 ? <span className='day2'>20日</span> : ''}
          {data.day3 ? <span className='day3'>21日</span> : ''}
        </div>
      </div>
    </SlideUpAnimation>
  );
}

export default ArtistBoxView;
