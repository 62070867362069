enum Strings {
  login = 'ログイン',
  logout = 'ログアウト',
  signup = '新規登録',
  noEntryEvent = '受付中のイベントはありません。',
  noData = 'データがありません。',
  copyRights = 'IDOLWARS実行委員会 2024 All Right Reserved.',
  notFoundPages = 'ページが見つかりません。URLにお間違えが無いかご確認ください。',
}

export default Strings;
