import React from 'react';
import { Link } from 'react-router-dom';
import './menu.scss';

interface MenuProp {
  displayMode: string
}
const Menu = (props: MenuProp) => {
  const {displayMode} = props;

  return (
    <div className='menu-view' style={{display: `${displayMode}`}}>
      <ul className='nav-ul'>
        <li className='nav-li'><Link to='/about' className='nav-link'>ABOUT<div>関ヶ原唄姫合戦</div></Link></li>
        <li className='nav-li'><Link to='/ticket' className='nav-link'>TICKET<div>チケット</div></Link></li>
        <li className='nav-li'><Link to='/lineup' className='nav-link'>LINE UP<div>出演者情報</div></Link></li>
        <li className='nav-li'>
          <div className='nav-link'>TIMETABLE<div>タイムテーブル</div></div>
          <ul className='dropdown-menu'>
            <li className='dropdown-li'><Link to='/live' className='nav-link'>LIVE<div>ライブ</div></Link></li>
            <li className='dropdown-li'><Link to='/greeting' className='nav-link'>GREETING<div>物販・特典会</div></Link></li>
          </ul>
        </li>
        <li className='nav-li'>
          <div className='nav-link'>SHOP<div>ショップ</div></div>
          <ul className='dropdown-menu'>
            <li className='dropdown-li'><Link to='/goods' className='nav-link'>GOODS<div>グッズ</div></Link></li>
            <li className='dropdown-li'><Link to='/foods' className='nav-link'>FOODS<div>飲食情報</div></Link></li>
          </ul>
        </li>
        <li className='nav-li'><Link to='/notice' className='nav-link'>NOTICE<div>注意事項</div></Link></li>
        <li className='nav-li'>
          <div className='nav-link'>MAP<div>マップ</div></div>
          <ul className='dropdown-menu'>
            <li className='dropdown-li'><Link to='/map' className='nav-link'>MAP<div>マップ</div></Link></li>
            <li className='dropdown-li'><Link to='/access' className='nav-link'>ACCESS<div>アクセス</div></Link></li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
