import React from 'react';
import './outline.scss';
import logo from '../image/logo.svg';

const OutlineView = () => {
  return (
    <div className='outline'>
      <img src={logo} alt='' />
      <div className='outline-area'>
        <div className='row'>
          <div className='col-head'>公演名</div>
          <div className='col'>SEKIGAHARA IDOL WARS 2024 - 関ケ原唄姫合戦- 10thAnniversary </div>
        </div>
        <div className='row'>
          <div className='col-head'>日程</div>
          <div className='col'>2024年7月19日(金)、20日(土)、21日(日)</div>
        </div>
        <div className='row'>
          <div className='col-head'>会場</div>
          <div className='col'>桃配運動公園</div>
        </div>
        <div className='row'>
          <div className='col-head'>主催</div>
          <div className='col'>IDOLWARS実行委員会</div>
        </div>
      </div>
    </div>
  );
}

export default OutlineView;
