import React from 'react';
import { AccordionView } from '../component/accordion/accordion.tsx';
import './yosen.scss';
import edo11 from '../image/edo/1/1.jpeg';
import edo12 from '../image/edo/1/2.jpeg';
import edo21 from '../image/edo/2/1.jpeg';
import edo22 from '../image/edo/2/2.jpeg';
import edo31 from '../image/edo/3/1.jpeg';
import edo32 from '../image/edo/3/2.jpeg';
import edo41 from '../image/edo/4/1.jpeg';
import edo42 from '../image/edo/4/2.jpeg';
import edo51 from '../image/edo/5/1.jpeg';
import edo52 from '../image/edo/5/2.jpeg';
import edo61 from '../image/edo/6/1.jpeg';
import edo62 from '../image/edo/6/2.jpeg';
import edo71 from '../image/edo/7/1.jpeg';
import edo72 from '../image/edo/7/2.jpeg';
import edo81 from '../image/edo/8/1.jpeg';
import edo82 from '../image/edo/8/2.jpeg';
import edo91 from '../image/edo/9/1.jpeg';
import edo92 from '../image/edo/9/2.jpeg';
import edo101 from '../image/edo/10/1.jpeg';
import edo102 from '../image/edo/10/2.jpeg';
import edo111 from '../image/edo/11/1.jpeg';
import edo112 from '../image/edo/11/2.jpeg';
import edo121 from '../image/edo/12/1.jpeg';
import edo131 from '../image/edo/13/1.jpeg';
import edo141 from '../image/edo/14/1.jpeg';
import edo151 from '../image/edo/15/1.jpeg';
import edo161 from '../image/edo/16/1.jpeg';

export const EdoView = () => {
  return (
    <div className='edo-view'>
      <AccordionView
        className='edo'
        title='其の十六'
        date='2024年5月26日（日）'
        place='シアターマーキュリー新宿'
        open='16:00'
        start='16:30'
        artists='アポロンの翼/ヒトノユメ/マイノリティアラート/FloreRisa-フロレリーサ-/Blancanie/Shupines/えすれある/Lily wonder/カリギュラ'
        image1={edo161}
        ticket='https://tiget.net/events/322794'
      />
      <AccordionView
        className='edo'
        title='其の十五'
        date='2024年5月26日（日）'
        place='シアターマーキュリー新宿'
        open='11:15'
        start='11:45'
        artists='FloreRisa-フロレリーサ-/Blancanie/えすれある/蓋然性オルトイズム/SUGAR☆VEGA.com /キュン!?恋堕ちキューピッド'
        image1={edo151}
        ticket='https://tiget.net/events/322792'
      />
      <AccordionView
        className='edo'
        title='其の十四'
        date='2024年5月25日（土）'
        place='シアターマーキュリー新宿'
        open='16:30'
        start='17:00'
        artists='アポロンの翼/ヒトノユメ/キミニハネ/マイノリティアラート/Blancanie/Shupines/My Sugar Light/ハレとハレ！/カリギュラ/SUGAR☆VEGA.com'
        image1={edo141}
        ticket='https://tiget.net/events/322555'
      />

      <AccordionView
        className='edo'
        title='其の十三'
        date='2024年5月25日（土）'
        place='シアターマーキュリー新宿'
        open='11:15'
        start='11:45'
        artists='アポロンの翼/ヒトノユメ/キミニハネ/FloreRisa-フロレリーサ-/Shupines/Lily wonder /ワガマき/SUGAR☆VEGA.com'
        image1={edo131}
        ticket='https://tiget.net/events/322553'
      />
      
      <AccordionView
        className='edo'
        title='其の十二'
        date='2024年5月22日（水）'
        place='GOTANDA G7'
        open='17:15'
        start='17:45'
        artists='アポロンの翼/もふる×クロス/キミニハネ/マイノリティアラート/Shupines/えすれある/My Sugar Light/Lily wonder/Cent Heaven/Lって何のLですか？'
        image1={edo121}
        ticket='https://tiget.net/events/321654'
      />

      <AccordionView
        className='edo'
        title='其の十一'
        date='2024年5月21日（火）'
        place='TwinBoxAKIHABARA/TwinBoxGARAGE'
        open='18:00'
        artists='マイノリティアラート/Lily wonder/FloreRisa-フロレリーサ-/えすれある/AMOUR/蓋然性オルトイズ/LOVE9LOVE/ハレとハレ！/Cent Heaven/ワガマき/Lって何のLですか？/SUGAR☆VEGA.com/Blancanie'
        image1={edo111}
        image2={edo112}
        ticket='https://tiget.net/events/321503'
      />

      <AccordionView
        className='edo'
        title='其の十'
        date='2024年5月20日（月）'
        place='GOTANDA  G7'
        open='17:15'
        start='17:45'
        artists='もふる×クロス/キミニハネ/Shupines/えすれある/AMOUR/My Sugar Light/Le☆miel/蓋然性オルトイズ/Cent Heaven/Lって何のLですか？'
        image1={edo101}
        image2={edo102}
        ticket='https://tiget.net/events/321384'
      />

      <AccordionView
        className='edo'
        title='其の九'
        date='2024年5月19日（日）'
        place='シアターマーキュリー新宿'
        open='18:15'
        start='18:30'
        artists='アポロンの翼/もふる×クロス/ヒトノユメ/キミニハネ/Le☆miel/ハレとハレ！'
        image1={edo91}
        image2={edo92}
        ticket='https://tiget.net/events/321132'
      />

      <AccordionView
        className='edo'
        title='其の八'
        date='2024年5月19日（日）'
        place='シアターマーキュリー新宿'
        open='11:15'
        start='11:45'
        artists='アポロンの翼/もふる×クロス/ヒトノユメ/キミニハネ/えすれある/SITRA./Le☆miel/SUGAR☆VEGA.com/ハレとハレ！/ワガマき/キュン!?恋堕ちキューピッド/カリギュラ/Blancanie'
        image1={edo81}
        image2={edo82}
        ticket='https://tiget.net/events/321130'
      />

      <AccordionView
        className='edo'
        title='其の七'
        date='2024年5月18日（土）'
        place='シアターマーキュリー新宿'
        open='16:30'
        start='17:00'
        artists='もふる×クロス/ヒトノユメ/キミニハネ/FloreRisa-フロレリーサ-/Blancanie/SITRA./Lily wonder/蓋然性オルトイズ/LOVE9LOVE/SUGAR☆VEGA.com'
        image1={edo71}
        image2={edo72}
        ticket='https://tiget.net/events/320932'
      />

      <AccordionView
        className='edo'
        title='其の六'
        date='2024年5月18日（土）'
        place='シアターマーキュリー新宿'
        open='11:15'
        start='11:45'
        artists='アポロンの翼/もふる×クロス/ヒトノユメ/FloreRisa-フロレリーサ-/えすれある/Lily wonder/My Sugar Light'
        image1={edo61}
        image2={edo62}
        ticket='https://tiget.net/events/320931'
      />

      <AccordionView
        className='edo'
        title='其の五'
        date='2024年5月16日（木）'
        place='TwinBoxGARAGE'
        open='18:30'
        start='19:00'
        artists='もふる×クロス/マイノリティアラート/えすれある/My Sugar Light/Cent Heaven'
        image1={edo51}
        image2={edo52}
        ticket='https://tiget.net/events/320694'
      />

      <AccordionView
        className='edo'
        title='其の四'
        date='2024年5月14日（火）'
        place='TwinBoxGARAGE'
        open='18:00'
        start='18:30'
        artists='もふる×クロス/FloreRisa-フロレリーサ-/Shupines/Lily wonder/LOVE9LOVE'
        image1={edo41}
        image2={edo42}
        ticket='https://tiget.net/events/320242'
      />

      <AccordionView
        className='edo'
        title='其の三'
        date='2024年5月12日（日）'
        place='TwinBoxGARAGE'
        open='17:00'
        start='17:30'
        artists='アポロンの翼/ヒトノユメ/キミニハネ/マイノリティアラート/Blancanie/Shupines/Lily wonder/ハレとハレ！'
        image1={edo31}
        image2={edo32}
        ticket='https://tiget.net/events/319997'
      />

      <AccordionView
        className='edo'
        title='其の二'
        date='2024年5月12日（日）'
        place='TwinBoxGARAGE'
        artists='もふる×クロス/ヒトノユメ/キミニハネ/Lily wonder/蓋然性オルトイズ'
        image1={edo21}
        image2={edo22}
        ticket='https://tiget.net/events/319996'
      />

      <AccordionView
        className='edo'
        title='其の一'
        date='2024年5月11日（土）'
        open='15:45'
        start='16:15'
        place='シアターマーキュリー新宿'
        artists='アポロンの翼/もふる×クロス/ヒトノユメ/キミニハネ/マイノリティアラート/Blancanie/Shupines/えすれある/Lily wonder/My Sugar Light/蓋然性オルトイズ/SUGAR☆VEGA.com'
        image1={edo11}
        image2={edo12}
        ticket='https://tiget.net/events/319635'
      />
    </div>
  )
}
