import React from 'react';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './foods.scss';
import CommingSoonView from '../commingSoon/commingSoon.tsx';

const MainView = () => {
  return (
    <div className='foods'>
      <Container maxWidth='md'>
        <TitleView english='FOODS' japaniese='飲食店情報'/>
        <CommingSoonView />
      </Container>
    </div>
  );
}

const FoodsView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default FoodsView;
