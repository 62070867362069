import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import { Container } from '@mui/material'
import './home.scss'
import BaseView from '../component/view/baseView.tsx'
import NewsList from '../news/newsList.tsx'
import ContentTitleView from '../component/title/contentTitleView.tsx'
import SponsorshipView from './sponsorship.tsx'
import conceptBack from '../image/concept-back.webp'
import logoWhite from '../image/logo_white.svg'
import cooperationData from '../resource/cooperation.json'
import sponsorData from '../resource/sponsor.json'
import TwitterTimeline from '../component/timeline/twitterTimeline.tsx'
import { SlArrowRight } from "react-icons/sl"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import imgUrls from '../resource/homeSlide.json'
import ImgView from '../component/view/imgView.tsx'
import CircleButton from '../component/button/circleButton.tsx'
import { IoTicketSharp } from 'react-icons/io5'

const Home = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [introShow, isIntroShow] = useState(false)
  const [introLogoShow, isIntroLogoShow] = useState(false)
  const [anivLogoShow, isAnivLogoShow] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('isIntroShowed')) {
      isIntroShow(true)
      localStorage.setItem('isIntroShowed', 'true')
      isIntroLogoShow(true)
      setTimeout(() => {
        isIntroLogoShow(false)
        isAnivLogoShow(true)
      }, 3000)
      setTimeout(() => {
        isIntroShow(false)
      }, 6000)
    }
    videoRef.current?.play()
  }, [])

  return (
    <div className='home'>
      <div className='top-view'>
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          pagination={{ clickable: false, el: '#pagination' }}
          mousewheel={true}
          autoplay={{delay: 5000, disableOnInteraction: false}}
          speed={500}
          centeredSlides={true}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 30
            },
          }}
        >
          {Object.values(imgUrls).map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <ImgView imgUrl={data} height='500px'/>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className='home-outline'>
          <img className='day-svg' src='https://sekigahara-idolwars.net/static/media/day.png' />
        </div>
      </div>
      <Container maxWidth='md'>
        <div className='home-news'>
          <ContentTitleView>お知らせ</ContentTitleView>
          <NewsList max={3}/>
          <div className='news-link'><Link to='/news'>お知らせ一覧</Link></div>
        </div>
        <div className='home-about'>
          <ContentTitleView>関ヶ原唄姫合戦とは</ContentTitleView>
          <Link to='/about'>
            <div className='about-box'>
              <img src={conceptBack} alt='concept-view'/>
              <div className='logo'>
                <img src={logoWhite} alt='logo'/>
              </div>
            </div>
          </Link>
        </div>
        <div className='home-ticket'>
          <ContentTitleView>チケット</ContentTitleView>
          <Link to='/ticket'>
            <div className='ticket-view'>
              <div className='ticket-content'>
                チケット情報へ
                <SlArrowRight />
              </div>
            </div>
          </Link>
        </div>
        <div className='sponsorship'>
          <ContentTitleView>協賛</ContentTitleView>
          <div className='view-container'>
            <SponsorshipView data={sponsorData} />
          </div>
          <ContentTitleView>協力</ContentTitleView>
          <div className='view-container'>
            <SponsorshipView data={cooperationData} />
          </div>
        </div>
        <div className='twitter-timeline'>
          <ContentTitleView>公式X</ContentTitleView>
          <Container className='timeline-area' maxWidth='sm'>
            <TwitterTimeline />
          </Container>
        </div>
      </Container>
      <Link to='/ticket'>
        <CircleButton className='ticket-circle-btn'>
          <IoTicketSharp className='ticket-icon'/>
          <div className='ticket-fs'>チケット情報</div>
        </CircleButton>
      </Link>
    
      <Modal isOpen={introShow} className='intro-view intro-modal' overlayClassName='overlay'>
        <div className='intro-logo'>
          <img style={{display: `${introLogoShow ? 'block' : 'none'}`}} src='https://sekigahara-idolwars.net/static/media/headerlogo.svg' alt='イントロロゴ' />
          <div className='anv-logo' style={{display: `${anivLogoShow ? 'block' : 'none'}`}}>
            <div className='logo-10'>
              10<span className='th'>th</span>
            </div>
            <div className='aniv'>Anniversary</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const HomeView = () => {
  return (
    <BaseView main={<Home/>} />
  )
}

export default HomeView
