import React from 'react';
import { AccordionView } from '../component/accordion/accordion.tsx';
import './yosen.scss';
import owari11 from '../image/owari/1/1.jpeg';
import owari12 from '../image/owari/1/2.jpeg';
import owari21 from '../image/owari/2/1.jpeg';
import owari22 from '../image/owari/2/2.jpeg';
import owari31 from '../image/owari/3/1.jpeg';
import owari32 from '../image/owari/3/2.jpeg';
import owari41 from '../image/owari/4/1.jpeg';
import owari42 from '../image/owari/4/2.jpeg';
import owari51 from '../image/owari/5/1.jpeg';
import owari52 from '../image/owari/5/2.jpeg';
import owari61 from '../image/owari/6/1.jpeg';
import owari62 from '../image/owari/6/2.jpeg';
import owari71 from '../image/owari/7/1.jpeg';
import owari72 from '../image/owari/7/2.jpeg';
import owari81 from '../image/owari/8/1.jpeg';
import owari82 from '../image/owari/8/2.jpeg';
import owari91 from '../image/owari/9/1.jpeg';
import owari101 from '../image/owari/10/1.jpeg';
import owari111 from '../image/owari/11/1.jpeg';
import owari121 from '../image/owari/12/1.jpeg';

export const OwariView = () => {
  return (
    <div className='owari-view'>
      <AccordionView
        className='owari'
        title='其の十二'
        date='2024年5月26日（日）'
        place='SOUND SPACE DEEP'
        open='17:30'
        start='18:00'
        artists='究極人形/OS☆K/AMOUR/ミラクルファンファーレ！/PrincessGarden-NAGOYA-'
        image1={owari121}
        ticket='https://tiget.net/events/322798'
      />
      <AccordionView
        className='owari'
        title='其の十一'
        date='2024年5月26日（日）'
        place='SOUND SPACE DEEP'
        open='13:00'
        start='13:30'
        artists='究極人形/AQA/AMOUR/ミラクルファンファーレ！/PrincessGarden-NAGOYA-'
        image1={owari111}
        ticket='https://tiget.net/events/322796'
      />
      <AccordionView
        className='owari'
        title='其の十'
        date='2024年5月25日（土）'
        place='SOUND SPACE DEEP'
        open='18:00'
        start='18:30'
        artists='究極人形/AQA/OS☆K/PrincessGarden-NAGOYA-'
        image1={owari101}
        ticket='https://tiget.net/events/322560'
      />
      <AccordionView
        className='owari'
        title='其の九'
        date='2024年5月25日（土）'
        place='SOUND SPACE DEEP'
        open='14:00'
        start='14:30'
        artists='究極人形/AQA'
        image1={owari91}
        ticket='https://tiget.net/events/322558'
      />
      <AccordionView
        className='owari'
        title='其の八'
        date='2024年5月19日（日）'
        place='SOUND SPACE DIVA'
        open='17:30'
        start='18:00'
        artists='究極人形/AMOUR/PrincessGarden-NAGOYA-/AQA/ミラクルファンファーレ！/マイノリティアラート/OS☆K'
        image1={owari81}
        image2={owari82}
        ticket='https://tiget.net/events/321143'
      />
      <AccordionView
        className='owari'
        title='其の七'
        date='2024年5月19日（日）'
        place='SOUND SPACE DIVA'
        open='13:00'
        start='13:30'
        artists='究極人形/AMOUR/PrincessGarden-NAGOYA-/Cent Heaven'
        image1={owari71}
        image2={owari72}
        ticket='https://tiget.net/events/321142'
      />
      <AccordionView
        className='owari'
        title='其の六'
        date='2024年5月18日（土）'
        place='Lion Limited'
        open='17:45'
        start='18:15'
        artists='AQA/OS☆K/AMOUR/Le☆miel/PrincessGarden-NAGOYA-/ミラクルファンファーレ！'
        image1={owari61}
        image2={owari62}
        ticket='https://tiget.net/events/320944'
      />
      <AccordionView
        className='owari'
        title='其の五'
        date='2024年5月18日（土）'
        place='Lion Limited'
        open='13:00'
        start='13:30'
        artists='Le☆miel/PrincessGarden-NAGOYA-/Cent Heaven/究極人形'
        image1={owari51}
        image2={owari52}
        ticket='https://tiget.net/events/320942'
      />
      <AccordionView
        className='owari'
        title='其の四'
        date='2024年5月15日（水）'
        place='NAGOYA ReNY limited'
        open='17:45'
        start='18:15'
        artists='究極人形/AQA/AMOUR/Le☆miel/ミラクルファンファーレ！/PrincessGarden-NAGOYA-/Cent Heaven'
        image1={owari41}
        image2={owari42}
        ticket='https://tiget.net/events/320462'
      />
      <AccordionView
        className='owari'
        title='其の三'
        date='2024年5月12日（日）'
        place='名古屋DEEP'
        artists='究極人形/AMOUR/SITRA./Le☆miel'
        image1={owari31}
        image2={owari32}
        ticket='https://tiget.net/events/319999'
      />
      <AccordionView
        className='owari'
        title='其の二'
        date='2024年5月11日（土）'
        place='名古屋DEEP'
        open='17:00'
        start='17:30'
        artists='AQA/OS☆K/Le☆miel/ミラクルファンファーレ！'
        image1={owari21}
        image2={owari22}
        ticket='https://tiget.net/events/319807'
      />
      <AccordionView
        className='owari'
        title='其の一'
        date='2024年5月11日（土）'
        place='名古屋DEEP'
        open='12:00'
        start='12:30'
        artists='究極人形/AMOUR/AQA/<ゲスト>mistress'
        image1={owari11}
        image2={owari12}
        ticket='https://tiget.net/events/319806'
      />
    </div>
  )
}