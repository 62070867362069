import React from 'react';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './timetable.scss';
import stage1Data from '../resource/stage1.json';
import stage2Data from '../resource/stage2.json';
import stage3Data from '../resource/stage3.json';
import stage4Data from '../resource/stage4.json';
import stage5Data from '../resource/stage5.json';
import TimeTable from '../component/timetable/timetableView.tsx';

const config = {
  tableHeadCellHeight: 100,
  tableCellHeight: 20,
  contentMargin: 2,
  contentPadding: 5,
  borderBoldInterval: 6,
  timeStringInterval: 3
}

interface date {
  year: string,
  month: string,
  day: string,
  youbi: string
}

const MainView = () => {

  const DateView = (props: date) => {
    const {year,month,day,youbi} = props;
    return (
      <div className='date-area'>
        {year}<span className='fs-sm'>年</span>{month}<span className='fs-sm'>月</span>{day}<span className='fs-sm'>日({youbi})</span>
      </div>
    );
  }

  return (
    <div className='timetable'>
      <Container maxWidth='lg'>
        <TitleView english='GREETING TIME TABLE' japaniese='物販・特典会タイムテーブル'/>
        <div>
          <DateView year='2024' month='7' day='19' youbi='金'/>
          <div className='timetable-area' id='target-component'>
            <TimeTable config={config} class={'stage1'} date={stage1Data.date} data={stage1Data.data} stageName={stage1Data.stageName} isTimeStringViewLeft={true} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage2'} date={stage2Data.date} data={stage2Data.data} stageName={stage2Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage4'} date={stage4Data.date} data={stage4Data.data} stageName={stage4Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} />
            <TimeTable config={config} class={'stage5'} date={stage5Data.date} data={stage5Data.data} stageName={stage5Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={true} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const GreetingTableView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default GreetingTableView;
