import React from 'react';
import { Container } from '@mui/material';
import html2canvas from 'html2canvas';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './timetable.scss';
import stage1Data from '../resource/stage1.json';
import stage2Data from '../resource/stage2.json';
import stage3Data from '../resource/stage3.json';
import stage4Data from '../resource/stage4.json';
import stage5Data from '../resource/stage5.json';
import TimeTable from '../component/timetable/timetableView.tsx';
import tokugawa from '../image/tokugawa_stage.png';
import toyotomi from '../image/toyotomi_stage.png';
import sengoku from '../image/sengoku_stage.png';
import momokubari from '../image/momokubari_stage.png';
import gekokujo from '../image/gekokujo_stage.png';

const config = {
  tableHeadCellHeight: 100,
  tableCellHeight: 20,
  contentMargin: 2,
  contentPadding: 5,
  borderBoldInterval: 6,
  timeStringInterval: 3
}

interface date {
  year: string,
  month: string,
  day: string,
  youbi: string
}

const saveAsImage = (uri) => {
  const downloadLink = document.createElement('a');

  if (typeof downloadLink.download === 'string') {
    downloadLink.href = uri;

    // ファイル名
    downloadLink.download = 'idol-wars-2024_timetable.png';

    // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
    document.body.appendChild(downloadLink);

    // ダウンロードリンクが設定された a タグをクリック
    downloadLink.click();

    // Firefox 対策で追加したリンクを削除しておく
    document.body.removeChild(downloadLink);
  } else {
    window.open(uri);
  }
}

const exportPng = (targetId: string) => {
  // 画像に変換する component の id を指定
  const target = document.getElementById(targetId);
  html2canvas(target).then(canvas => {
    const targetImgUri = canvas.toDataURL('img/png');
    saveAsImage(targetImgUri);
  });
}

const MainView = () => {

  const DateView = (props: date) => {
    const {year,month,day,youbi} = props;
    return (
      <div className='date-area'>
        {year}<span className='fs-sm'>年</span>{month}<span className='fs-sm'>月</span>{day}<span className='fs-sm'>日({youbi})</span>
      </div>
    );
  }

  return (
    <div className='timetable'>
      <Container maxWidth='lg'>
        <TitleView english='TIME TABLE' japaniese='タイムテーブル'/>
        <button onClick={() => {exportPng('target-component')}}>png出力</button>
        <div>
          <DateView year='2024' month='7' day='19' youbi='金'/>
          <div className='timetable-area' id='target-component'>
            <TimeTable config={config} class={'stage1'} date={stage1Data.date} data={stage1Data.data} stageName={stage1Data.stageName} isTimeStringViewLeft={true} isTimeStringViewRight={false} img={tokugawa} />
            <TimeTable config={config} class={'stage2'} date={stage2Data.date} data={stage2Data.data} stageName={stage2Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} img={toyotomi} />
            <TimeTable config={config} class={'stage3'} date={stage3Data.date} data={stage3Data.data} stageName={stage3Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} img={sengoku} />
            <TimeTable config={config} class={'stage4'} date={stage4Data.date} data={stage4Data.data} stageName={stage4Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={false} img={momokubari} />
            <TimeTable config={config} class={'stage5'} date={stage5Data.date} data={stage5Data.data} stageName={stage5Data.stageName} isTimeStringViewLeft={false} isTimeStringViewRight={true} img={gekokujo} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const TimetableView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default TimetableView;
