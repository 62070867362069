import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import './newsDetail.scss';
import newsList from '../resource/news.json';
import NotFoundView from '../notfound/notFound.tsx';

const MainView = () => {
  const newsId = useParams();
  const newsIndex = newsList.findIndex(news => 
    news.id === Number(newsId.newsId)
  );
  const newsData = newsList[newsIndex];
  if (newsData) {
    return (
      <div className='news-detail'>
        <Container maxWidth='md'>
          <div className='date'>{newsData.date}</div>
          <div className='news-title'>{newsData.title}</div>
          <div className='context'>
            {Object.values(newsData.context).map((text, index) => {
              return text ? <div key={index}>{text}</div> : <br></br>
            })}
          </div>
          {Object.values(newsData.img).map((img, index) => {
            return (
              <div className='news-img-area' key={index}>
                <img src={img} alt='' />
              </div>
            );
          })}
          <div className='news-back-area'>
            <Link to='/news' className='back'>ニュース一覧</Link>
          </div>
        </Container>
      </div>);
  } else {
    return (<NotFoundView/>);
  }
}

const NewsDetailView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default NewsDetailView;
