import React from 'react';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './ticket.scss';
import CommingSoonView from '../commingSoon/commingSoon.tsx';

const MainView = () => {
  return (
    <div className='ticket'>
      <Container maxWidth='md'>
        <TitleView english='TICKET' japaniese='チケット情報'/>
        <CommingSoonView />
      </Container>
    </div>
  );
}

const TicketView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default TicketView;
