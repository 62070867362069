import React from 'react';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './notice.scss';
import CommingSoonView from '../commingSoon/commingSoon.tsx';

const MainView = () => {
  return (
    <div className='notice'>
      <Container maxWidth='md'>
        <TitleView english='NOTICE' japaniese='注意事項'/>
        <div className='asobi-view'>
          <div className='asobi'>不正！</div>
          <div className='asobi'>ダメ！</div>
          <div className='asobi'>絶対！</div>
        </div>
      </Container>
    </div>
  );
}

const NoticeView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default NoticeView;
