import React from 'react';
import Youtube from 'react-youtube';
import './youtubeView.scss';

interface YoutubeProp {
  videoId: string,
  title: string
}
const YoutubeView = (props: YoutubeProp) => {
  const {videoId, title} = props;
  return (
    <div className='youtube-view'>
      <div className='youtube-title'>{title}</div>
      <Youtube videoId={videoId} className='video-area' />
    </div>
  );
}

export default YoutubeView;
