import React, { useState } from 'react';
import Modal from 'react-modal';
import { Container, Grid } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './goods.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SlideUpAnimation from '../component/animation/slideUpAnimation.tsx';
import data from '../resource/goods.json';

interface Data {
  data: GoodsData
}

type GoodsData = {
  goodsName: string,
  sozai: string,
  size: string,
  price: string,
  image: Array<string>,
  other?: string
}

interface SliderData {
  data: Array<string>;
}

const Slider = (props: SliderData) => {
  const {data} = props;

  return (
    <div className='goods-swiper'>
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true, el: '#pagination' }}
        mousewheel={true}
        centeredSlides={true}
      >
        {Object.values(data).map((data, index) => {
          return (
            <SwiperSlide key={index}>
              <div className='img-area'>
                <img className='goods-img' src={data} alt=''/>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div id='pagination' className='swiper-pagination custom-pagination'></div>
    </div>
  );
}

const MainView = () => {

  const replaceMsg = (msg: string) => {
    return msg.replace(/\n/g, '<br />');
  }

  const GoodsBoxModal = (props: Data) => {
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const {data} = props;
    return (
      <SlideUpAnimation>
        <div className='modal-base goods-modal-base'>
          <button className='modal-btn' onClick={() => setEditModalIsOpen(true)}>
            <img src={data.image[0]} alt='' />
          </button>
          <div>
            <div className='goods-name'>{replaceMsg(data.goodsName)}</div>
            <div className='goods-price'>{data.price}</div>
            <div className='goods-size'>{data.size}</div>
            <div className='goods-sozai'>{data.sozai}</div>
            {data.other ? <div className='goods-other'>{data.other}</div> : ''}
          </div>
          <Modal isOpen={editModalIsOpen} onRequestClose={() => (setEditModalIsOpen(false))} overlayClassName='overlay' className='modal-md'>
            <Slider data={data.image} />
            <div className='modal-footer'>
              <button onClick={() => (setEditModalIsOpen(false))} className='modal-close-btn'>
              </button>
            </div>
          </Modal>
        </div>
      </SlideUpAnimation>
    );
  }

  return (
    <div className='goods'>
      <Container maxWidth='md'>
        <TitleView english='GOODS' japaniese='グッズ情報'/>
        <Grid container style={{alignItems: 'center', justifyContent: 'center'}}>
          {Object.values(data).map((data, index) => {
            return (
              <Grid item xs={6} key={index}>
                <GoodsBoxModal data={data} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

const GoodsView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default GoodsView;
