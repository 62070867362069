import React from 'react';
import { Container } from '@mui/material';
import BaseView from '../component/view/baseView.tsx';
import TitleView from '../component/title/titleView.tsx';
import './map.scss';
import CommingSoonView from '../commingSoon/commingSoon.tsx';

const MainView = () => {
  return (
    <div className='map'>
      <Container maxWidth='lg'>
        <TitleView english='MAP' japaniese='エリアマップ'/>
        <CommingSoonView />
        {/* <div className='map-img-view'>
          <img src='https://pbs.twimg.com/media/F1UAJPHagAEAVFC.jpg' alt='map1' />
        </div> */}
      </Container>
    </div>
  );
}

const MapView = () => {
  return (
    <BaseView main={<MainView/>} />
  );
}

export default MapView;
