import React from 'react';
import './commingSoon.scss';

interface comSProp {
  children?: React.ReactNode;
}

const CommingSoonView = (props: comSProp) => {
  const {children} = props;

  return (
    <div className='com-soon'>
      <div className='message'>Comming Soon ...</div>
      <div>{children}</div>
    </div>
  );
}

export default CommingSoonView;
